<template>
  <click-to-edit
    v-model="scope.row.scale.weight_plan"
    type="input"
    :number-format="{}"
    :disabled="!isFieldAvailableForEdit(scope.row, 'scale.weight_plan')"
    placeholder="Введите вес"
    @input="saveElementPropChange(scope.row, 'scale', {weight_plan:$event})"
  ></click-to-edit>
</template>

<script>

import ClickToEdit from "@/components/ClickToEdit";
import {ElementPropTableColumn} from "@/mixins/elementPropTableColumn";
export default {
  name: 'element-weight-plan-table-column',
  components: {ClickToEdit},
  mixins: [ElementPropTableColumn],

  props: {},
  data() {
    return {

    }
  },
  computed: {},
  methods: {

  }
}

</script>

<style scoped lang="scss">
@import "@/assets/css/project-variables";

</style>
